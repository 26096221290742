<template>
  <div>
    <b-overlay
      :show="isLoading"
      spinner-variant="primary"
      class="pt-3"
      rounded="lg"
    >
      <div v-if="step == 1">
        <h3 class="mb-4">Я реєструюсь щоб</h3>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            v-model="user.role"
            :options="role_options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <b-button
          size="lg"
          variant="primary"
          class="w-100 mt-4"
          @click="step = 2"
        >
          Зареєструватися як
          <span v-if="user.role == 'employee'">роботодавець</span>
          <span v-else>фахівець</span>
        </b-button>
        <div class="py-4 text-center">
          Вже зареєстровані?
          <a href="#" @click.prevent="switchToLogin">Увійти</a>
        </div>
      </div>
      <div v-if="step == 2">
        <b-form @submit="createAccount">
          <h3 class="pb-4">
            Зареєструватись як
            <span v-if="user.role == 'employee'">роботодавець</span>
            <span v-else>фахівець</span>
          </h3>
          <div class="row mb-3">
            <div class="col-6">
              <div class="input-group mb-2">
                <label class="d-block w-100">Імʼя</label>
                <b-form-input v-model.trim="user.first_name"> </b-form-input>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group mb-2">
                <label class="d-block w-100">Прізвище</label>
                <b-form-input v-model.trim="user.last_name"> </b-form-input>
              </div>
            </div>
          </div>
          <div class="input-group mb-2">
            <label class="d-block w-100">Email</label>
            <b-form-input v-model.trim="user.email"> </b-form-input>
          </div>
          <div class="input-group mb-2">
            <label class="d-block w-100">Пароль</label>
            <b-form-input type="password" v-model.trim="user.password">
            </b-form-input>
          </div>
          <div class="input-group mb-2">
            <label class="d-block w-100">Пароль повторно</label>
            <b-form-input type="password" v-model.trim="user.password_repeat">
            </b-form-input>
          </div>
          <div class="mb-4 mt-4">
            <b-button
              :disabled="registerDisabled"
              size="lg"
              variant="primary"
              class="w-100"
              @click="createAccount"
            >
              Створити аккаунт
            </b-button>
          </div>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      user: {
        role: "employee",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_company: false,
      },
      role_options: [
        { text: "Знайти роботу", value: "worker" },
        { text: "Надати роботу", value: "employee" },
      ],
      isLoading: false,
      error_msg: [
        {
          code: "auth/invalid-email",
          msg: "Невірний Email",
        },
        {
          code: "auth/internal-error",
          msg: "Введіть пароль",
        },
        {
          code: "auth/wrong-password",
          msg: "Невірний пароль",
        },
        {
          code: "auth/user-not-found",
          msg: "Користувача не існує",
        },
        {
          code: "auth/missing-email",
          msg: "Відсутній Email",
        },
        {
          code: "auth/email-already-in-use",
          msg: "Користувач з таким Email вже існує",
        },
      ],
    };
  },
  computed: {
    registerDisabled() {
      let disabled = false;
      if (this.user.password.length < 4) {
        disabled = true;
      }
      if (this.user.password_repeat != this.user.password) {
        disabled = true;
      }
      if (this.user.first_name.length < 3) {
        disabled = true;
      }
      if (this.user.last_name.length < 3) {
        disabled = true;
      }

      return disabled;
    },
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      let result = await this.$auth.createUserWithEmail(
        this.user.email,
        this.user.password
      );
      console.log(result);
      if (result.success) {
        let user = this.user;
        delete user["password_repeat"];
        delete user["password"];
        this.$store.commit("SET_ROLE", user.role);
        await this.$db.set("users", result.user.uid, this.user);
        this.$toast.success("Реєстрація успішна");
      } else {
        let correctMsg = this.error_msg.find((el) => {
          return result.code == el.code;
        });
        this.$toast.error(correctMsg.msg);
      }

      this.isLoading = false;
    },
    switchToLogin() {
      this.$emit("switchToLogin");
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  padding-right: 48px;
}
</style>
