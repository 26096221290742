<template>
  <header
    class="header d-flex flex-column align-items-center"
    :class="{ blue: view == 'workers' }"
  >
    <div class="header__view-switch w-100">
      <div class="container">
        <a
          href="#"
          v-if="view == 'vacancies'"
          @click.prevent="setView('workers')"
          >Роботодавцю <b-icon-arrow-right />
        </a>
        <a
          href="#"
          v-if="view == 'workers'"
          @click.prevent="setView('vacancies')"
          >Шукачеві <b-icon-arrow-right />
        </a>
      </div>
    </div>
    <div class="container py-2">
      <div class="row align-items-center justify-content-between">
        <div class="col-3">
          <router-link class="header__logo" to="/">
            <icon
              variant="logo"
              :color="view == 'vacancies' ? '#000' : '#fff'"
            />
          </router-link>
        </div>
        <div class="col-5 d-flex justify-content-end">
          <header-user />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import headerUser from "./header/header-user.vue";
export default {
  components: {
    headerUser,
  },
  data() {
    return {};
  },
  computed: {
    view() {
      return this.$store.state.view;
    },
  },
  methods: {
    setView(view) {
      this.$store.dispatch("setView", view);
    },
  },
};
</script>

<style lang="scss">
.header {
  background: var(--yellow);
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0;
  z-index: 999;
  transition: 0.3s;
  &.blue {
    background: var(--blue);
    transition: 0.3s;
    .sidebar-toggle {
      color: #fff !important;
    }
    .header__user {
      & > a {
        color: #fff;
      }
    }
    .header__logo {
      svg {
        fill: #fff !important;
      }
    }
  }
  &__view-switch {
    background: #000;
    text-align: right;
    padding: 5px 0px;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid;
        color: #fff;
      }
    }
  }
  &__logo {
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: #1d1d1b;
    text-decoration: none;
    svg {
      width: 150px !important;
      height: 42px !important;
    }
  }
}
</style>
