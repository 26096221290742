<template>
  <div>
    <b-card
      class="mb-3 work__info-card"
      :class="{ deactivated: !v.is_active }"
      v-if="v"
    >
      <b-overlay :show="updateLoading" variant="white">
        <div class="work__info d-flex">
          <div class="work__street">
            <router-link
              :to="{
                name: 'vacancy-search',
                query: {
                  l_1: v.location.ids.l_1,
                  l_2: v.location.ids.l_2,
                  l_3: v.location.ids.l_3,
                },
              }"
            >
              {{ v.location_string }}
            </router-link>
          </div>
          <span class="ms-1 me-1">•</span>
          <div class="work__date">
            {{ v.updated_at.seconds | moment("calendar") }}
          </div>
        </div>
        <div class="work__category mt-3">
          <router-link
            :to="{ name: 'vacancy-search', query: { category: v.cat_id } }"
          >
            {{ categoryName }}
          </router-link>
        </div>
        <h2 class="work__title mt-0">
          <router-link :to="{ name: 'vacancy-single', params: { uid: v.uid } }">
            {{ v.name }}
          </router-link>
        </h2>
        <div class="work__salary d-flex mt-2" v-if="v.salary">
          <div class="work__salary-amount">{{ v.salary }}</div>
          <div class="work__salary-type">грн./міс.</div>
        </div>
        <div class="work__tags">
          <span v-for="t in v.job_type" :key="t">
            {{ getTypeName(t) }}
          </span>
        </div>
        <div
          class="work__controls d-column d-md-flex pt-3"
          v-if="(user && v.created_by == user.uid) || role == 'admin'"
        >
          <b-button
            variant="primary me-2"
            size="sm"
            :to="{ name: 'vacancy-edit', params: { uid: v.uid } }"
          >
            Редагувати
          </b-button>
          <b-button variant="danger me-2" @click="removeModal = true" size="sm">
            Видалити
          </b-button>

          <b-button
            class="ms-auto"
            @click="toggleActive"
            size="sm"
            :variant="v.is_active ? 'secondary' : 'black'"
          >
            <span v-if="v.is_active"> Зняти з публікації </span>
            <span v-else> Опублікувати </span>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
    <b-modal v-model="removeModal" centered hide-footer hide-header>
      <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
        <h2 class="mb-2">Справді видалити вакансію?</h2>
        <h2 class="mb-3">
          <strong>{{ v.name }}</strong>
        </h2>
        <b-button @click="removeVacancy" class="w-100 mt-2" variant="danger"
          >Видалити вакансію</b-button
        >
        <b-button
          @click="removeModal = false"
          class="w-100 mt-2"
          variant="outline-danger"
          >Скасувати</b-button
        >
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["v"],
  data() {
    return {
      isLoading: false,
      updateLoading: false,
      removeModal: false,
    };
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
    user() {
      return this.$store.getters.user;
    },
    job_types() {
      return this.$store.getters.constants.jobtype;
    },
    categories() {
      return this.$store.getters.categories;
    },
    categoryName() {
      let name = "";
      if (this.v.cat_id) {
        name = this.categories.find((c) => c.id == this.v.cat_id).title;
      }

      return name;
    },
  },
  methods: {
    getTypeName(val) {
      return this.job_types.find((el) => {
        return el.value == val;
      }).label;
    },
    async toggleActive() {
      this.updateLoading = true;
      let active = !this.v.is_active;
      let now = new Date();
      let result = await this.$db.update("vacancies", this.v.uid, {
        updated_at: now,
        is_active: active,
      });

      if (result.success) {
        this.$toast.success("Вакансію оновлено");
        this.v.is_active = active;
        this.v.updated_at = now;
      }

      this.updateLoading = false;
    },
    async removeVacancy() {
      this.isLoading = true;
      let result = await this.$db.delete("vacancies", this.v.uid);
      if (result.success) {
        this.removeModal = false;
        this.$toast.warning("Вакансія видалена");
        this.$emit("remove");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
  &__tags {
    margin-top: 12px;
    span {
      background: rgba(25, 77, 186, 0.08);
      border-radius: 50px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      display: inline-block;
      color: #194dba;
      padding: 6px 12px;
      margin-bottom: 8px;
    }
  }
  &__street {
    a {
      text-decoration: none;
    }
  }
  &__category {
    a {
      font-size: 13px;
      text-decoration: none;
    }
  }
  &__controls {
    @media (max-width: 768px) {
      padding-top: 10px;
      .btn {
        margin-bottom: 10px;
        min-width: 100%;
      }
    }
  }
  &__info-card {
    border: 1px solid #e0e3e9;
    &.deactivated {
      filter: grayscale(1);
    }
  }
  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1d1d1b;
    opacity: 0.5;
  }
  &__title {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.3;

    a {
      text-decoration: none;
      color: #0d6efd;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        color: darken(#0d6efd, 15%);
      }
    }
  }
  &__company {
    margin: 16px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1d1d1b;
  }
  &__salary-amount {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #1d1d1b;
  }
  &__salary-amount {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #1d1d1b;
  }
  &__salary-type {
    text-align: end;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
    line-height: 39px;
    color: #1d1d1b;
    opacity: 0.75;
  }
  &__tag {
    font-style: normal;
    margin-right: 8px;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #194dba;
    padding: 4px 8px;
    background: #edf1fa;
    border-radius: 40px;
  }
}
</style>
