<template>
  <div style="display: none">
    <svg id="icon-logo" viewBox="0 0 322 71" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0518 0.690974C15.1006 1.04142 13.8991 1.3418 13.3984 1.3418C12.9479 1.39187 11.5461 1.84244 10.3445 2.44321C4.98772 4.9464 2.03396 9.30195 0.732303 16.5612C-0.218909 22.2184 -0.268973 47.9512 0.732303 54.0089C2.23422 63.1205 5.989 67.4761 14.4998 69.8791C18.7052 71.0807 52.6485 71.0807 56.4533 69.8791C62.8615 67.8766 66.1657 65.3233 68.3184 60.7675C70.5212 56.0615 70.8717 52.4069 70.8717 35.4352C70.8717 18.4636 70.5212 14.809 68.3184 10.103C66.1657 5.54716 62.8615 2.94384 56.4533 0.991357C52.8988 -0.110046 18.8554 -0.410429 16.0518 0.690974ZM41.5844 18.7139C45.0388 19.7152 47.9925 21.7678 49.7447 24.271C51.7473 27.1247 51.547 27.7254 48.2929 28.7768L45.5394 29.6278L43.5368 27.2748C39.6319 22.7691 33.5741 22.4687 29.2186 26.624C27.9169 27.8756 26.9657 29.2774 26.7655 30.2787L26.4651 31.9308H32.7231C36.1775 31.9308 39.3315 32.081 39.7821 32.2312C40.3328 32.4314 40.5831 33.1824 40.5831 34.6843C40.5831 36.1862 40.3328 36.9372 39.7821 37.1374C39.3315 37.2876 36.2275 37.4378 32.8232 37.4378C29.4189 37.4378 26.5152 37.638 26.3149 37.9384C25.9144 38.5893 27.8669 42.5944 29.2186 43.846C30.5203 45.0475 34.1248 46.4493 35.9772 46.4493C39.2814 46.4493 42.5856 44.5969 44.6883 41.543C45.4393 40.4416 45.5895 40.3916 47.6421 40.9923C48.8436 41.3428 50.1453 41.8935 50.5458 42.2439C52.1979 43.7458 48.2428 48.5019 43.687 50.5545C41.8347 51.4056 40.2827 51.6058 36.3277 51.6559C31.9721 51.6559 30.9708 51.5057 28.5678 50.3542C23.5113 48.0013 20.4574 43.846 19.5062 37.9885C18.6551 32.5816 20.2071 26.7742 23.4613 23.2197C27.7667 18.6138 35.1261 16.8115 41.5844 18.7139Z"
      />
      <path
        d="M207.296 10.3533C207.296 11.9053 205.593 12.6562 199.786 13.9078C196.882 14.5086 193.328 15.61 191.876 16.3109C185.618 19.3648 182.314 26.2235 182.264 35.9359C182.264 44.647 185.618 51.0551 191.726 54.059C194.93 55.611 202.439 55.661 205.844 54.109C209.048 52.6572 211.651 50.2541 213.203 47.2503C215.055 43.7458 215.106 37.4378 213.403 33.683C210.85 28.0759 206.495 25.4225 200.036 25.4225C195.731 25.4225 193.077 26.3236 189.873 28.7768L188.171 30.0784L188.522 28.8769C190.074 23.5701 192.527 21.9681 202.039 19.9155C205.894 19.1145 209.598 18.013 210.249 17.5124C212.152 16.1106 213.303 14.3083 213.604 12.3558C213.904 10.5535 213.854 10.5535 212.002 10.2031C210.9 10.0028 209.448 9.75252 208.697 9.60233C207.546 9.35201 207.296 9.5022 207.296 10.3533ZM201.889 30.9295C204.192 31.5803 207.145 34.7844 207.596 37.1875C207.746 38.1888 207.796 40.1913 207.696 41.6932C206.895 51.0051 194.129 52.8574 190.224 44.1964C188.622 40.742 189.273 35.9859 191.676 33.2324C193.778 30.8794 198.084 29.8782 201.889 30.9295Z"
      />
      <path
        d="M106.167 33.8832V54.9601H109.671H113.176V46.9499V38.9397H119.834C131.149 38.9397 136.055 36.5366 138.008 30.0784C140.01 23.4199 137.307 17.0117 131.249 14.2082C129.146 13.2569 127.895 13.1568 117.582 13.0066L106.167 12.8064V33.8832ZM127.895 19.9154C132.45 22.2184 132.801 28.5264 128.546 31.6304C127.294 32.5315 126.243 32.6817 120.135 32.8319L113.176 33.0321V25.9231V18.814L119.834 19.0143C124.39 19.1144 126.893 19.4148 127.895 19.9154Z"
      />
      <path
        d="M154.578 21.9681C146.418 23.8705 141.512 32.0309 142.763 41.4429C143.564 47.2503 146.618 51.5558 151.675 53.9088C154.078 55.0102 155.179 55.2104 159.234 55.2104C163.289 55.2104 164.391 55.0102 166.744 53.9088C170.699 52.0564 172.501 50.3042 174.253 46.7497C176.456 42.294 176.807 37.4378 175.305 32.4314C174.354 29.3775 173.753 28.3262 171.55 26.1735C169.397 24.0207 168.246 23.3198 165.492 22.4687C161.988 21.3673 157.883 21.1671 154.578 21.9681ZM164.241 28.3262C165.192 28.8769 166.644 30.2286 167.495 31.3801C168.746 33.1824 168.997 34.0835 169.197 37.2876C169.397 42.0937 168.696 44.4968 166.243 46.9499C161.087 52.1065 152.075 49.8536 149.972 42.7946C146.869 32.2812 155.279 23.7203 164.241 28.3262Z"
      />
      <path
        d="M232.678 21.9681C224.518 23.8705 219.611 32.0309 220.863 41.4429C221.664 47.2503 224.718 51.5558 229.774 53.9088C232.177 55.0102 233.279 55.2104 237.334 55.2104C241.389 55.2104 242.49 55.0102 244.843 53.9088C248.798 52.0564 250.601 50.3042 252.353 46.7497C254.556 42.294 254.906 37.4378 253.404 32.4314C252.453 29.3775 251.852 28.3262 249.65 26.1735C247.497 24.0207 246.345 23.3198 243.592 22.4687C240.087 21.3673 235.982 21.1671 232.678 21.9681ZM242.34 28.3262C243.291 28.8769 244.743 30.2286 245.594 31.3801C246.846 33.1824 247.096 34.0835 247.297 37.2876C247.497 42.0937 246.796 44.4968 244.343 46.9499C239.186 52.1065 230.175 49.8536 228.072 42.7946C224.968 32.2812 233.379 23.7203 242.34 28.3262Z"
      />
      <path
        d="M299.313 22.1683C296.509 22.9193 291.903 25.4225 291.202 26.574C290.802 27.2248 291.052 27.8256 292.604 29.3775L294.556 31.3801L296.359 29.9282C298.511 28.2261 302.216 26.9244 304.92 26.9244C309.976 26.9244 313.681 30.6792 310.977 33.1323C310.427 33.633 307.873 34.3338 304.619 34.9346C295.658 36.4866 292.203 38.2388 290.702 41.9936C288.499 47.4506 291.252 53.1578 296.859 54.8099C301.515 56.1617 308.124 54.6597 310.977 51.6559C312.329 50.2041 312.93 50.1039 312.93 51.3555C312.93 51.8562 313.531 52.8074 314.232 53.5083C315.233 54.4595 316.034 54.7098 318.337 54.7098H321.19L321.341 51.8061C321.491 49.1027 321.441 48.9525 320.289 48.9525C318.086 48.9525 317.936 48.2516 317.936 39.2401C317.936 34.5341 317.686 29.778 317.386 28.6766C315.783 22.9694 307.373 19.9155 299.313 22.1683ZM311.428 41.3928C311.428 46.4493 308.224 49.4531 302.316 49.8536C299.663 50.0539 299.363 49.9537 297.911 48.552C295.908 46.5494 295.908 44.3967 297.811 42.4442C299.463 40.8421 300.714 40.4416 311.077 38.4891C311.278 38.4891 311.428 39.7407 311.428 41.3928Z"
      />
      <path
        d="M256.858 24.9218V27.9256H262.866H268.874V41.4429V54.9601H272.128H275.382V41.4429V27.9256H281.39H287.397V24.9218V21.918H272.128H256.858V24.9218Z"
      />
    </svg>

    <svg
      id="icon-dropdown"
      viewBox="0 -93 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m441.667969 0-185.667969 185.667969-185.667969-185.667969-70.332031 70.332031 256 256 256-256zm0 0"
      />
    </svg>
    <svg
      id="icon-location"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10ZM14 8.2C14 4.57 11.35 2 8 2C4.65 2 2 4.57 2 8.2C2 10.54 3.95 13.64 8 17.34C12.05 13.64 14 10.54 14 8.2ZM8 0C12.2 0 16 3.22 16 8.2C16 11.52 13.33 15.45 8 20C2.67 15.45 0 11.52 0 8.2C0 3.22 3.8 0 8 0Z"
        fill="#1D1D1B"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
