import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/home.vue";
import Login from "../views/Login.vue";
import vacancy from "../views/vacancy-single/vacancy-single.vue";
import vacancyCreate from "../views/vacancy-single/vacancy-single-create.vue";
import vacancySearch from "@/views/vacancy-search/vacancy-search.vue";

import resumeCreate from "@/views/resume-single/resume-single-create.vue";
import resumeSingle from "@/views/resume-single/resume-single.vue";
import resumeSearch from "@/views/resume-search/resume-search.vue";

import Admin from "@/views/admin/index.vue";
import AdminHome from "@/views/admin/admin-home.vue";
import AdminUser from "@/views/admin/admin-user.vue";

import learn from "@/views/learn/learn.vue";

import My from "@/views/my/my.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/vacancy/search/",
    name: "vacancy-search",
    component: vacancySearch,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/vacancy/view/:uid",
    name: "vacancy-single",
    component: vacancy,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/vacancy/edit/:uid",
    name: "vacancy-edit",
    component: vacancyCreate,
    meta: {
      rule: "user",
    },
  },
  {
    path: "/vacancy/create/",
    name: "vacancy-create",
    component: vacancyCreate,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/resume/search/",
    name: "resume-search",
    component: resumeSearch,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/resume/view/:uid",
    name: "resume-single",
    component: resumeSingle,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/resume/edit/:uid",
    name: "resume-edit",
    component: resumeCreate,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/learn",
    name: "learn",
    component: learn,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/resume/create/",
    name: "resume-create",
    component: resumeCreate,
    meta: {
      rule: "anonymous",
    },
  },
  {
    path: "/my",
    name: "my",
    component: My,
    meta: {
      rule: "user",
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    redirect: "home",
    meta: {
      rule: "admin",
    },
    children: [
      {
        path: "home",
        name: "admin-home",
        meta: {
          rule: "admin",
        },
        component: AdminHome,
      },
      {
        path: "user/:uid",
        name: "admin-user",
        component: AdminUser,
        meta: {
          rule: "admin",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      rule: "anonymous",
    },
  },
];

const router = new VueRouter({
  scrollBehavior(to, from) {
    if (to.name !== from.name) {
      window.scrollTo(0, 0);
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
