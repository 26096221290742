<template>
  <div class="home-categories">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Категорії кандидатів</h2>
          <div class="categories">
            <div class="categories__list">
              <b-overlay
                :show="isLoading"
                variant="white"
                style="min-height: 200px"
              >
                <template v-if="!isLoading">
                  <div class="row">
                    <router-link
                      :to="{ name: 'resume-search', query: { category: c.id } }"
                      v-for="c in categories"
                      :key="c.id"
                      class="d-flex justify-content-between col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      {{ c.title }}
                      <span class="category__amount ms-4">
                        {{ getCount(c.id) }}
                      </span>
                    </router-link>
                  </div>
                </template>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      counts: {},
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
  },
  methods: {
    async getCounts() {
      this.isLoading = true;

      let result = await this.$db.getDocument("counters", "resumes");
      this.counts = result;

      this.isLoading = false;
    },
    getCount(id) {
      let count = 0;
      if (this.counts[id]) {
        count = this.counts[id];
      }
      return count;
    },
  },
  mounted() {
    this.getCounts();
  },
};
</script>

<style lang="scss" scoped>
.home-categories {
  background: #fff;
  padding: 60px 0px;
}
a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
  text-decoration: none;
  margin-bottom: 20px;
  span {
    color: rgba(29, 29, 27, 0.5);
  }
}
</style>
