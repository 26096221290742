<template>
  <b-modal v-model="showModal" hide-header hide-footer>
    <div class="auht-modal">
      <div v-if="use_case == 'login'">
        <loginForm
          @switchToSignUp="use_case = 'signup'"
          @close="showModal = false"
        />
      </div>
      <div v-if="use_case == 'signup'">
        <signupForm @switchToLogin="use_case = 'login'" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import loginForm from "@/components/auth/login-form.vue";
import signupForm from "@/components/auth/signup-form.vue";
export default {
  props: ["show"],
  components: {
    loginForm,
    signupForm,
  },
  data() {
    return {
      use_case: "login",
      showModal: false,
      isLoading: false,
      user: {
        email: "",
        password: "",
        password_repeat: "",
      },
      error_msg: [
        {
          code: "auth/invalid-email",
          msg: "Невірний Email",
        },
        {
          code: "auth/internal-error",
          msg: "Введіть пароль",
        },
        {
          code: "auth/wrong-password",
          msg: "Невірний пароль",
        },
        {
          code: "auth/user-not-found",
          msg: "Користувача не існує",
        },
        {
          code: "auth/missing-email",
          msg: "Відсутній Email",
        },
        {
          code: "auth/email-already-in-use",
          msg: "Ця електронна адреса вже використовується",
        },
      ],
    };
  },
  watch: {
    userData: {
      handler() {
        if (this.userData) {
          this.showModal = false;
        }
      },
    },
  },
  computed: {
    userData() {
      return this.$store.state.user;
    },
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      let result = await this.$auth.senResetEmail(this.user.email);
      console.log(result);
      if (result.success == true) {
        this.use_case = "email_sent";
      } else {
        let correctMsg = this.error_msg.find((el) => {
          return result.errorCode == el.code;
        });
        this.$toast.error(correctMsg.msg);
      }
      this.isLoading = false;
    },
    async signIn() {
      this.isLoading = true;
      var result = await this.$auth
        .signInWithEmail(this.user.email, this.user.password)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          result = error;
        });
      this.isLoading = false;
      if (result.success == true) {
        this.$toast.success("Виконано вхід");
        this.$emit("login");
        if (this.$route.path == "/my") {
          window.location.reload();
        }
      } else {
        let correctMsg = this.error_msg.find((el) => {
          return result.code == el.code;
        });
        this.$toast.error(correctMsg.msg);
      }
    },
  },
  mounted() {
    window.addEventListener("openAuthLogin", () => {
      this.use_case = "login";
      this.showModal = true;
    });
    window.addEventListener("openAuthSignup", () => {
      this.use_case = "signup";
      this.showModal = true;
    });
  },
};
</script>

<style lang="scss">
.auth-modal {
  .btn {
    &-primary {
      border: none;
      background: #1d1d1a;
      color: #fff;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        background: #000;
        border: none;
      }
      &.disabled,
      &:disabled {
        background: #666;
        border: none;
        opacity: 0.5;
      }
    }
  }
}
</style>
