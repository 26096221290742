<template>
  <div class="text-length" :class="{ error: text.length > maxLength }">
    {{ text.length }} /
    {{ maxLength }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    maxLength: Number,
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: #f00;
}
.text-length {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}
</style>
