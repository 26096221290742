<template>
  <div>
    <homeSearchVaccancy />
    <homeLatestVacancies />
  </div>
</template>

<script>
import homeSearchVaccancy from "./home-search-vacancy.vue";
import homeLatestVacancies from "./home-latest-vacancies.vue";
export default {
  components: {
    homeSearchVaccancy,
    homeLatestVacancies,
  },
};
</script>

<style lang="scss" scoped></style>
