<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="my__vacancies">
        <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
          <resumeCard v-for="r in resumes" :key="r.uid" :r="r" />
        </b-overlay>
      </div>
    </div>
    <div class="col-12 col-md-4"></div>
  </div>
</template>

<script>
import resumeCard from "@/components/list-items/resume-card.vue";
export default {
  components: {
    resumeCard,
  },
  data() {
    return {
      isLoading: false,
      resumes: [],
    };
  },
  methods: {
    async getMyResumes() {
      this.isLoading = true;
      let args = {
        collectionName: "resumes",
        where_1: ["created_by", "==", this.$store.state.user.uid],
        order: ["updated_at", "desc"],
      };

      let reuslt = await this.$db.getCollection(args);

      this.resumes = reuslt.docs;

      this.isLoading = false;
    },
  },
  mounted() {
    this.getMyResumes();
  },
};
</script>

<style lang="scss" scoped>
.my {
  &__vacancies {
  }
}
</style>
