<template>
  <div class="vacancy-info mb-4">
    <div class="vacancy-info__date" v-if="r.updated_at">
      {{ r.updated_at.seconds | moment("calendar") }}
    </div>
    <h1>{{ r.name }}</h1>

    <hr />
    <h4>Про кандидата</h4>
    <p v-html="r.description"></p>
  </div>
</template>

<script>
export default {
  props: ["r", "isLoading"],
};
</script>

<style lang="scss" scoped>
.vacancy-info {
  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1d1d1b;
    opacity: 0.7;
    margin-bottom: 16px;
  }
  h1 {
    font-style: bold;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #1d1d1b;
    margin-bottom: 25px;
  }
  .line {
    width: 100%;
    margin-bottom: 24px;
    height: 1px;
    background: #e0e3e9;
  }
}
</style>
