<template>
  <div>
    <learningResources v-if="$route.name !== 'learn'" />

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <span class="footer__work">
              <icon
                variant="logo"
                style="height: 42px; width: 130px"
                :color="'#fff'"
              />
            </span>
          </div>
          <div class="footer__about-site">
            © 2022 Сервіс пошуку роботи в Україні.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import learningResources from "@/components/footer/learning-resources.vue";
export default {
  components: {
    learningResources,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 32px 0px;
  margin-top: auto;

  background: #1d1d1b;
  &__work {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #fff;
    margin-bottom: 20px;
  }
  &__about-site {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #fff;
  }
}
</style>
