export default {
  jobtype: [
    {
      value: 100,
      label: "Повна зайнятість",
    },
    {
      value: 200,
      label: "Неповна зайнятість",
    },
    {
      value: 300,
      label: "Дистанційна робота",
    },
    {
      value: 400,
      label: "Разова робота",
      highlight: true,
    },
  ],
};
