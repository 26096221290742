<template>
  <div class="location-select" :class="{ creation: inCreation }">
    <div class="location-select__toggle" @click="openModal">
      <div class="icon-start" :class="{ creation: inCreation }">
        <icon variant="location" />
      </div>
      <div class="location-select__selected">
        <span v-if="!selected_string">
          <span v-if="!inCreation"> Вся Україна </span>
          <span v-else> Оберіть нас. пункт </span>
        </span>
        <span v-else>{{ selected_string }}</span>
        <div class="icon-end">
          <icon variant="dropdown" />
        </div>
      </div>
    </div>
    <!-- Modals -->
    <b-modal v-model="showModal" size="lg" centered hide-header hide-footer>
      <b-overlay
        :show="isLoading"
        variant="white"
        style="min-height: 200px"
        class="pb-4"
      >
        <template v-if="level == 'l_1'">
          <h3 class="mb-4">Оберіть область</h3>
          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-4"
              v-for="(region, index) in regions"
              :key="index"
            >
              <a
                class="location-item"
                href="#"
                @click.prevent="getCities(region)"
              >
                {{ region.n }}
              </a>
            </div>
          </div>
        </template>
        <template v-if="level == 'l_2'">
          <a href="#" @click.prevent="level = 'l_1'" class="back-button"
            >Назад</a
          >
          <h3 class="mb-4">Оберіть місто/район</h3>

          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-4"
              v-for="(city, index) in cities"
              :key="index"
            >
              <a class="location-item" href="#" @click.prevent="getTowns(city)">
                {{ city.n }}
              </a>
            </div>
          </div>
        </template>
        <template v-if="level == 'l_3'">
          <a href="#" @click.prevent="level = 'l_2'" class="back-button"
            >Назад</a
          >
          <h3 class="mb-4">Оберіть нас. пункт</h3>
          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-4"
              v-for="(town, index) in towns"
              :key="index"
              @click.prevent="selectLocation(town)"
            >
              <a class="location-item" href="#">
                {{ town.n }}
              </a>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import regions from "@/assets/data/regions.json";
import dictionary from "@/assets/data/koatuu.json";
export default {
  props: ["inCreation", "locationInput"],
  data() {
    return {
      showModal: false,
      isLoading: false,
      level: "l_1",
      all_regions: regions,
      dictionary: dictionary,
      cities: [],
      towns: [],
      selected: {
        l_1: null,
        l_2: null,
        l_3: null,
      },
      selected_string: null,
      selected_ids: {},
    };
  },
  computed: {
    regions() {
      function formattedRegion(region) {
        let words = region.toLowerCase().split(" ");

        words = words.map((word) => {
          let result = word[0].toUpperCase() + word.substring(1);
          return result;
        });
        words = words.join(" ");
        words = words.replace("М.київ", "м.Київ");

        return words.replace("Область", "обл.");
      }

      return this.all_regions.map((region) => {
        return {
          n: formattedRegion(region.n),
          l_1: region.l_1,
        };
      });
    },
  },
  watch: {
    showModal: {
      handler() {
        if (!this.showModal) {
          setTimeout(() => {
            this.level = "l_1";
          }, 600);
        }
      },
    },
  },
  methods: {
    async getCities(region) {
      function formattedCity(region) {
        let words = region.toLowerCase().split(" ");

        words = words.map((word) => {
          let result = word[0].toUpperCase() + word.substring(1);
          return result;
        });
        words = words.join(" ");
        words = words.replace("М.", "м.");
        return words;
      }
      this.isLoading = true;
      this.selected.l_1 = region;
      let cities = [];
      let dictionary = this.dictionary;
      for (let i = 0; i < dictionary.length; i++) {
        const item = dictionary[i];
        if (
          item.l_1.toString() === region.l_1.toString() &&
          item.l_2.toString().length > 2 &&
          item.l_3.toString().length < 3 &&
          item.l_2.toString().charAt(4) !== "0" &&
          item.c !== "Р"
        ) {
          if (item.n.indexOf("/") > -1) {
            item.n = formattedCity(item.n.split("/")[0]);
          }
          if (item.c && item.n.indexOf(".") < 0) {
            item.n = formattedCity(item.c + ". " + item.n);
          }
          cities.push(item);
        }
      }

      this.cities = cities;
      this.level = "l_2";
      this.isLoading = false;
    },
    async getTowns(city) {
      this.selected.l_2 = city;
      function formattedTown(region) {
        let words = region.toLowerCase().split(" ");

        words = words.map((word) => {
          let result = word[0].toUpperCase() + word.substring(1);
          return result;
        });
        words = words.join(" ");
        words = words.replace("М.", "м.");

        return words;
      }
      let dictionary = this.dictionary;
      const towns = [];
      for (let i = 0; i < dictionary.length; i++) {
        const item = dictionary[i];
        if (item.l_2.toString() === city.l_2.toString() && item.c.length > 0) {
          item.n = item.n.split("/")[0];
          if (item.c && item.n.indexOf(".") < 0) {
            if (item.c === "Т") {
              item.c = "смт";
            }
            if (item.c === "Щ") {
              item.c = "с";
            }
            item.n = item.c.toLowerCase() + ". " + formattedTown(item.n);
          }
          towns.push(item);
        }
      }

      if (towns.length > 1) {
        this.towns = towns;
        this.level = "l_3";
      } else {
        this.selectLocation(towns[0]);
      }
    },
    selectLocation(town) {
      if (town) {
        this.selected.l_3 = town;
      }
      let finalTown = this.selected.l_3.n
        ? this.selected.l_3.n
        : this.selected.l_2.n;
      this.selected_string = finalTown + ", " + this.selected.l_1.n;
      this.selected_ids = {
        l_1: this.selected.l_1.l_1,
        l_2: this.selected.l_2?.l_2,
        l_3: this.selected.l_3?.l_3,
      };
      this.showModal = false;
      this.$emit("select", {
        ids: this.selected_ids,
        string: this.selected_string,
      });
    },
    openModal() {
      this.showModal = true;
    },
    async getNamesByIds(locationInput) {
      let has_location = false;
      if (locationInput.l_1) {
        has_location = true;
        this.selected_ids.l_1 = locationInput.l_1;
        this.selected.l_1 = this.regions.find((region) => {
          return region.l_1 == locationInput.l_1;
        });
      }
      if (locationInput.l_2) {
        has_location = true;
        this.selected_ids.l_2 = locationInput.l_2;
        this.getCities(this.selected.l_1);
        this.selected.l_2 = this.cities.find((city) => {
          return city.l_2 == locationInput.l_2;
        });
      }
      if (locationInput.l_3) {
        this.getTowns(this.selected.l_2);
        this.selected_ids.dl_3 = locationInput.l_3;
        this.selected.l_3 = this.towns.find((town) => {
          return town.l_3 == locationInput.l_3;
        });
      } else {
        this.selected.l_3 = this.selected.l_2;
        this.selected_ids.l_3 = this.selected_ids.l_2;
      }
      if (has_location) {
        this.selectLocation();
      }
    },
  },
  mounted() {
    if (this.$route.name.indexOf("search") > -1) {
      this.getNamesByIds(this.$route.query);
    }
    if (this.locationInput) {
      this.getNamesByIds(this.locationInput);
    }
  },
};
</script>

<style lang="scss" scoped>
.location-item {
  display: block;
  color: #000;
  padding: 5px 10px;
  border-radius: 4px;
  &:hover {
    background: var(--primary-color);
    color: #fff;
    text-decoration: none;
  }
}
.back-button {
  margin-bottom: 20px;
  display: inline-block;
}
.location-select {
  @media (max-width: 991px) {
    min-width: 100%;
  }
  &__toggle {
    display: flex;
    align-items: center;
    height: 100%;
    width: 280px;
    max-width: 100%;
    @media (max-width: 991px) {
      min-width: 100%;
      width: 100%;
    }
    .icon-start {
      width: 42px;
      text-align: center;
      &:not(.creation) {
        @media (max-width: 991px) {
          text-align: left;
        }
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  cursor: pointer;
  &__selected {
    display: flex;
    align-items: center;
    min-width: calc(100% - 42px);
    justify-content: space-between;
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 991px) {
        padding-left: 10px;
      }
    }
    .icon-end {
      width: 42px;
      text-align: center;
      @media (max-width: 991px) {
        padding-right: 10px;
      }
    }
  }
}
</style>
