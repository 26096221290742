<template>
  <div class="vacancy-create pt-4">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9">
          <createMain />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createMain from "./create-components/create-main.vue";
export default {
  components: {
    createMain,
  },
};
</script>

<style lang="scss" scoped>
.vacancy-create {
}
</style>
