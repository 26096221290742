<template>
  <div class="vacancy-create mb-4">
    <b-card class="special-b-card mb-4" border-variant="light">
      <div class="form-input">
        <h1 v-if="usecase == 'create'">Розмістити резюме</h1>
        <h1 v-if="usecase == 'edit'">Редагувати резюме</h1>
        <div :class="{ error: validate && !valid.cat_id }">
          <v-select
            v-model="resume.cat_id"
            :options="categories"
            :reduce="(category) => category.value"
            :clearable="false"
            placeholder="Оберіть категорію"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <icon :variant="'dropdown'" />
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </b-card>
    <b-card border-variant="light">
      <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
        <div class="vacancy-create__name">
          <div class="form-input" :class="{ error: validate && !valid.name }">
            <h6>Бажана посада*</h6>
            <b-form-input
              v-model="resume.name"
              type="text"
              placeholder="Кур’єр доставки їжі"
            />
          </div>
        </div>
        <div class="vacancy-create__description">
          <div
            class="form-input"
            :class="{ error: validate && !valid.description }"
          >
            <h6>Коротко про себе*</h6>
            <vue-editor
              v-model="resume.description"
              :editor-toolbar="customToolbar"
              placeholder=""
            />
          </div>
        </div>

        <h1>Умови роботи</h1>
        <div
          class="vacancy-create__city form-input"
          :class="{ error: validate && !valid.location }"
        >
          <h6>Місто / Село</h6>
          <div class="input-border">
            <locationSelect
              :key="resume.created_by ? resume.created_by.id : null"
              :locationInput="locationInput"
              :inCreation="true"
              @select="selectLocation"
            />
          </div>
        </div>

        <div class="vacancy-create__sallary">
          <div class="vacancy-create__sallary-amount mt-4 pt-2">
            <h6>Бажана заробітна плата (від)</h6>
            <div
              class="vacancy-create__sallary-amount-input d-flex align-items-center"
              :class="{ error: validate && !valid.salary }"
            >
              <b-form-input
                type="number"
                v-model="resume.salary"
                placeholder="5000"
              />
              <span class="d-block w-100 ms-4">грн./міс.</span>
            </div>
          </div>
        </div>

        <div class="vacancy-create__jobtype mt-4 pt-2">
          <div class="form-input">
            <h6 class="mb-3" :class="{ error: validate && !valid.job_type }">
              Тип зайнятості
            </h6>
            <div class="custom-checkbox" v-for="j in jobtypes" :key="j.value">
              <label :for="'job-types-' + j.value">
                <input
                  :id="'job-types-' + j.value"
                  type="checkbox"
                  v-model="resume.job_type"
                  :value="j.value"
                  name="jobtype"
                />
                <div class="box">
                  <b-icon-check />
                </div>
                <span>
                  {{ j.label }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="vacancy-create__personal-information mt-4 pt-2">
          <h1>Контакна інформація</h1>
          <div
            class="form-input"
            :class="{ error: validate && !valid.contact_name }"
          >
            <h6>Контактна особа</h6>
            <div class="contact-input">
              <b-form-input
                v-model="resume.contact_name"
                type="email"
                placeholder="Імʼя"
              />
            </div>
          </div>
          <div
            class="form-input"
            :class="{ error: validate && !valid.contact_phone }"
          >
            <h6>Контактний телефон</h6>
            <div class="contact-input">
              <vue-tel-input
                v-model="resume.contact_phone"
                :mode="'international'"
                :autoDefaultCountry="false"
                :defaultCountry="'UA'"
                @validate="validatePhone"
              ></vue-tel-input>
            </div>
          </div>
          <div class="cta mt-4 pt-4">
            <b-button
              @click="save"
              size="lg"
              class="d-block w-100"
              variant="primary"
              v-if="usecase == 'create'"
            >
              <strong>
                <h5 class="mb-0">Розмістити резюме</h5></strong
              ></b-button
            >
            <b-button
              @click="updateVacancy"
              size="lg"
              class="d-block w-100"
              variant="primary"
              v-if="usecase == 'edit'"
            >
              <strong> <h5 class="mb-0">Зберегти зміни</h5></strong></b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import locationSelect from "@/components/search/location-select.vue";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { VueEditor } from "vue2-editor";
export default {
  components: {
    locationSelect,
    VueEditor,
  },
  data() {
    return {
      usecase: "create",
      isLoading: false,
      selected: null,
      phoneValid: false,
      validate: false,
      resume: {
        cat_id: null,
        name: "",
        description:
          "<p><strong>Досвід</strong>:&nbsp;</p><p><br></p><p><strong>Сильні сторони</strong>:&nbsp;</p><p><br></p><p><strong>Освіта</strong>:&nbsp;</p>",
        location: null,
        salary: "",
        contact_name: "",
        contact_phone: "",
        job_type: [],
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  computed: {
    jobtypes() {
      return this.$store.getters.constants.jobtype;
    },
    user() {
      return this.$store.state.user;
    },
    locationInput() {
      let location = null;
      if (this.usecase == "edit" && this.resume.location) {
        location = this.resume.location.ids;
      }
      return location;
    },
    valid() {
      let valid = {
        cat_id: true,
        name: true,
        description: true,
        location: true,
        salary: true,
        contact_name: true,
        contact_phone: true,
        job_type: true,
      };
      if (!this.resume.cat_id) {
        valid.cat_id = false;
      }
      if (!this.resume.name || this.resume.name.length < 8) {
        valid.name = false;
      }
      if (!this.resume.job_type.length) {
        valid.job_type = false;
      }
      if (!this.resume.description || this.resume.description.length < 20) {
        valid.description = false;
      }
      if (!this.resume.location) {
        valid.location = false;
      }
      if (!this.resume.salary || this.resume.salary < 1000) {
        valid.salary = false;
      }
      if (!this.resume.contact_name || this.resume.contact_name.length < 3) {
        valid.contact_name = false;
      }
      if (!this.resume.contact_phone || !this.phoneValid) {
        valid.contact_phone = false;
      }

      return valid;
    },
    categories() {
      return this.$store.getters.categories.map((el) => {
        return {
          value: el.id,
          label: el.title,
        };
      });
    },
  },
  methods: {
    validatePhone(value) {
      if (value.valid) {
        this.resume["formattedPhone"] = value.number;
        this.phoneValid = true;
      } else {
        this.phoneValid = false;
      }
    },
    selectLocation(location) {
      this.resume.location = location;
    },
    save() {
      let valid = true;
      this.validate = true;
      for (var key in this.valid) {
        if (this.valid[key] == false) {
          valid = false;
        }
      }

      if (valid) {
        this.createVacancy();
        this.validate = false;
      } else {
        this.$toast.error("Заповніть всі обовʼязкові поля");
      }
    },
    async createVacancy() {
      this.isLoading = true;
      let resume = this.resume;

      resume["created_at"] = new Date();
      resume["updated_at"] = new Date();
      resume["created_by"] = this.user.uid;
      resume["location_ids"] = [];
      for (var key in this.resume.location.ids) {
        if (this.resume.location.ids[key]) {
          resume["location_ids"].push(this.resume.location.ids[key]);
        }
      }
      resume["location_string"] = this.resume.location.string;
      resume["is_active"] = true;

      await this.$db
        .add("resumes", resume)
        .then((data) => {
          console.log(data);
          this.$toast.success("Резюме успішно опубліковану");
          setTimeout(() => {
            this.$router.push({ name: "my", query: { tab: "resumes" } });
          }, 400);
        })
        .catch(() => {
          this.$toast.warning("Щось пішло не так");
          this.isLoading = false;
        });
    },
    async updateVacancy() {
      this.isLoading = true;
      let resume = this.resume;
      resume.updated_at = new Date();
      resume["location_ids"] = [];
      for (var key in this.resume.location.ids) {
        if (this.resume.location.ids[key]) {
          resume["location_ids"].push(this.resume.location.ids[key]);
        }
      }
      resume["location_string"] = this.resume.location.string;
      let uid = this.$route.params.uid;
      let result = await this.$db.update("resumes", uid, resume);
      if (result.success) {
        this.$toast.success("Резюме успішно оновлено");
        setTimeout(() => {
          this.$router.push({ name: "my", query: { tab: "resumes" } });
        }, 400);
      } else {
        this.$toast.warning("Щось пішло не так");
      }
      this.isLoading = false;
    },
    async getResume() {
      this.isLoading = true;

      let uid = this.$route.params.uid;

      let result = await this.$db.getDocument("resumes", uid);
      this.resume = result;
      if (!this.resume.job_type) {
        this.resume.job_type = [];
      }

      this.isLoading = false;
    },
  },
  mounted() {
    if (this.$route.name == "resume-edit") {
      this.usecase = "edit";
      this.getResume();
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 24px;
}
.error {
  color: #f00;
}
.special-b-card {
  margin-bottom: 8px;
}
.vacancy-create {
  .line {
    width: 100%;
    height: 1px;
    background: #e0e3e9;
    margin: 24px 0;
  }
  &__name {
    input {
      padding: 15px 16px;
      margin-top: 8px;
    }
    margin-bottom: 16px;
  }
  &__description {
    input {
      padding: 16px 0 56px 16px;
      margin-top: 8px;
    }
    margin-bottom: 16px;
  }
  &__city {
    .location-select {
      max-width: 100%;
    }

    .input-border {
      height: 72px;
      border: 1px solid #e0e3e9;
      width: 300px;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    &.error {
      .input-border {
        border-color: #f00;
      }
    }
  }

  &__sallary {
    h6 {
      margin-right: 80px;
    }
  }
  &__sallary-amount {
    margin-top: 16px;
  }
  &__sallary-amount-input {
    width: 222px;
    input {
      height: 42px;
    }
  }
  &__additional-information {
    select:first-of-type {
      margin-bottom: 16px;
    }
    select {
      width: 348px;
      margin-bottom: 24px;
    }
  }
  &__personal-information {
    input {
      margin-bottom: 16px;
      height: 42px;
    }
  }
  .contact-input {
    width: 320px;
    .vue-tel-input {
      outline: none;
      height: 42px;
      &:focus-within {
        border-color: transparent;
      }
    }
  }
}
</style>
