<template>
  <div class="vacancy">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9">
          <b-card>
            <b-overlay
              :show="isLoading"
              variant="white"
              style="min-height: 200px"
            >
              <h4>Вакансія</h4>
              <mainInfo :v="vacancy" :isLoading="isLoading" />
            </b-overlay>
          </b-card>
          <b-overlay
            :show="relatedLoading"
            variant="white"
            style="min-height: 200px"
          >
            <template v-if="related.length">
              <h4 class="mt-4">Схожі вакансії</h4>
              <div
                class="vacancy__related-item"
                v-for="v in related"
                :key="v.uid"
              >
                <router-link
                  :to="{ name: 'vacancy-single', params: { uid: v.uid } }"
                >
                  <h5 class="mb-0">{{ v.name }}</h5>
                  <p>{{ v.location_string }}</p>
                </router-link>
              </div></template
            >
          </b-overlay>
        </div>

        <div class="col-12 col-md-3">
          <b-overlay
            :show="isLoading"
            variant="white"
            style="min-height: 200px"
          >
            <vacancySidebar :v="vacancy" :isLoading="isLoading" />
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainInfo from "./vacancy-single-main.vue";
import vacancySidebar from "./vacancy-single-sidebar.vue";
export default {
  data() {
    return {
      relatedLoading: false,
      isLoading: false,
      vacancy: {},
      related: [],
    };
  },
  watch: {
    $route: {
      handler() {
        this.getVacancy();
        window.scrollTo(0, 0);
      },
    },
  },
  components: {
    mainInfo,
    vacancySidebar,
  },
  methods: {
    async getRelatedVacancies() {
      this.relatedLoading = true;
      let vacancy = this.vacancy;
      let args = {
        collectionName: "vacancies",
        where_6: ["is_active", "==", true],
        order: ["updated_at", "desc"],
        where_1: ["cat_id", "==", vacancy.cat_id],
        limitBy: 9,
      };

      if (vacancy.l_2) {
        args["where_2"] = ["location_ids", "array-contains", vacancy.l_2];
      }
      if (vacancy.l_3) {
        args["where_2"] = ["location_ids", "array-contains", vacancy.l_3];
      }

      let result = await this.$db.getCollection(args);
      this.related = [];
      this.related = result.docs.filter((el) => {
        return el.uid != this.$route.params.uid;
      });

      this.relatedLoading = false;
    },
    async getVacancy() {
      this.isLoading = true;

      let uid = this.$route.params.uid;

      let result = await this.$db.getDocument("vacancies", uid);
      this.vacancy = result;
      this.getRelatedVacancies();
      this.isLoading = false;
    },
  },
  mounted() {
    this.getVacancy();
  },
};
</script>

<style lang="scss" scoped>
.vacancy {
  background: #f2f4f5;
  padding-top: 74px;
  padding-bottom: 60px;
  &__related-item {
    a {
      text-decoration: none;
      color: #156efd;
      &:hover {
        h5 {
          text-decoration: underline;
        }
      }
      p {
        color: #666;
      }
    }
  }
}
</style>
