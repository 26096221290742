<template>
  <div class="user">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <router-link
            :to="{
              name: 'admin-home',
              query: {
                tab: 'users',
              },
            }"
          >
            <b-icon-arrow-left />
            Всі користувачі
          </router-link>
          <h1 class="mt-3">Редагування користувача</h1>
          <b-overlay
            :show="userLoading"
            variant="white"
            style="min-height: 200px"
          >
            <div
              class="form-input mb-3 pt-3"
              :class="{ error: validate && !valid.first_name }"
            >
              <label>Імʼя</label>
              <b-form-input v-model="user.first_name"></b-form-input>
            </div>
            <div
              class="form-input mb-3"
              :class="{ error: validate && !valid.last_name }"
            >
              <label>Прізвище</label>
              <b-form-input v-model="user.last_name"></b-form-input>
            </div>
            <div class="form-input">
              <label>E-mail</label>
              <b-form-input disabled v-model="user.email"></b-form-input>
            </div>
            <div class="form-input pt-4">
              <b-button @click="save" variant="primary w-100">
                Зберегти
              </b-button>
            </div>
          </b-overlay>
        </div>
        <div class="col-12 col-md-6">
          <b-card>
            <h2>Вакансії користувача</h2>
            <b-overlay
              :show="isLoading"
              variant="white"
              style="min-height: 200px"
            >
              <vacancyCard v-for="v in user_vacancies" :v="v" :key="v.uid" />
            </b-overlay>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vacancyCard from "@/components/list-items/vacancy-card.vue";
export default {
  components: {
    vacancyCard,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      user_vacancies: [],
      errors: {},
      userLoading: false,
      isLoading: false,
      validate: false,
    };
  },
  computed: {
    valid() {
      let valid = {
        first_name: true,
        last_name: true,
      };

      if (this.user.first_name.length < 2) {
        valid.first_name = false;
      }
      if (this.user.last_name.length < 2) {
        valid.last_name = false;
      }

      return valid;
    },
  },
  methods: {
    save() {
      let valid = true;
      this.validate = true;
      for (var key in this.valid) {
        if (this.valid[key] == false) {
          valid = false;
        }
      }

      if (valid) {
        this.saveUser();
        this.validate = false;
      } else {
        this.$toast.error("Заповніть всі обовʼязкові поля");
      }
    },
    async saveUser() {
      this.userLoading = true;
      let user = Object.assign({}, this.user);
      delete user.email;
      let result = await this.$db.update("users", this.$route.params.uid, user);
      this.userLoading = false;
      if (result.success) {
        this.$toast.success("Зміни збережено");
      }
    },
    async getUser() {
      this.userLoading = true;

      let result = await this.$db.getDocument("users", this.$route.params.uid);
      this.user = result;
      this.getUserVacancies();
      this.userLoading = false;
    },
    async getUserVacancies() {
      this.isLoading = true;
      let args = {
        collectionName: "vacancies",
        order: ["updated_at", "desc"],
        where_1: ["created_by", "==", this.$route.params.uid],
      };
      let result = await this.$db.getCollection(args);
      this.user_vacancies = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped></style>
