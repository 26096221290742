<template>
  <div>
    <homeSearchWorker />
    <homeCategories />
  </div>
</template>

<script>
import homeSearchWorker from "./home-search-worker.vue";
import homeCategories from "./home-categories.vue";
export default {
  components: {
    homeSearchWorker,
    homeCategories,
  },
};
</script>

<style lang="scss" scoped></style>
