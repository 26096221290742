import Vue from "vue";
import Vuex from "vuex";

import constants from "@/constants/constants.js";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "kspa-crm",
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userData: null,
    role: "anonymous",
    tags: [],
    error: false,
    showSetup: false,
    constants: constants,
    view: "vacancies",
    library: {
      categories: [],
    },
  },
  getters: {
    constants: (state) => {
      return state.constants;
    },
    role: (state) => {
      return state.role;
    },
    view: (state) => {
      return state.view;
    },
    categories: (state) => {
      return state.library.categories;
    },
    tags: (state) => {
      return state.tags;
    },
    user: (state) => {
      return state.user;
    },
    userData: (state) => {
      return state.userData;
    },
    showSetup: (state) => {
      return state.showSetup;
    },
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_VIEW(state, view) {
      state.view = view;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    setCategories(state, payload) {
      state.library.categories = payload;
    },
    TOGGLE_SETUP(state, payload) {
      state.showSetup = payload;
    },
  },
  actions: {
    setRole(context, payload) {
      context.commit("SET_ROLE", payload);
    },
    setView(context, payload) {
      context.commit("SET_VIEW", payload);
    },
    toggleSetup(context, payload) {
      context.commit("TOGGLE_SETUP", payload);
    },
    setCategories(context, payload) {
      context.commit("setCategories", payload);
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
