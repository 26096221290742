import axios from "axios";

let baseURL = "https://us-central1-erobota-ua.cloudfunctions.net/api";
if (window.location.href.indexOf("localhost") > -1) {
  baseURL = "http://localhost:5001/erobota-ua/us-central1/api";
}

const axiosApi = axios.create({
  //Base URL for API calls
  baseURL: baseURL,
  // baseURL: "http://localhost:5001/citizen-drohobych/us-central1/api",
  // withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

let api = axiosApi;

export default api;
