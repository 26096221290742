<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
        <b-tabs content-class="mt-3">
          <b-tab title="Профіль" active>
            <div class="form-input mb-3">
              <label class="d-block"> <strong> Імʼя </strong></label>
              <b-form-input v-model="userData.first_name"></b-form-input>
            </div>
            <div class="form-input mb-3">
              <label class="d-block"> <strong> Прізвище </strong></label>
              <b-form-input v-model="userData.last_name"></b-form-input>
            </div>
            <div class="form-input mb-3">
              <label class="d-block"> <strong> E-mail </strong></label>
              <b-form-input disabled v-model="userData.email"></b-form-input>
            </div>
            <div @click="saveChanges" class="form-input pt-2 pb-4">
              <b-button variant="primary w-100"> Зберегти зміни </b-button>
            </div>
          </b-tab>
          <b-tab title="" disabled>
            <h4 class="mb-3 mt-4">У Вас ще немає профілю спеціаліста</h4>
            <b-button variant="black"> Створити профіль спеціаліста </b-button>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </div>
    <div class="col-12 col-md-4"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userData: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async saveChanges() {
      this.isLoading = true;
      let result = await this.$db.update("users", this.user.uid, this.userData);
      if (result.success) {
        this.$toast.success("Зміни збережено");
      }

      this.isLoading = false;
    },
    async getUserData() {
      this.isLoading = true;

      let result = await this.$db.getDocument("users", this.user.uid);

      this.userData = result;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 400px;
  max-width: 100%;
}
</style>
