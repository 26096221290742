<template>
  <div class="admin__latest-vacancies">
    <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
      <vacancyCard v-for="v in latest_vacancies" :key="v.uid" :v="v" />
    </b-overlay>
  </div>
</template>

<script>
import vacancyCard from "@/components/list-items/vacancy-card.vue";
export default {
  data() {
    return {
      isLoading: false,
      latest_vacancies: [],
    };
  },
  components: {
    vacancyCard,
  },
  methods: {
    async getLatest() {
      this.isLoading = true;
      let args = {
        collectionName: "vacancies",
        order: ["updated_at", "desc"],
        where_1: ["is_active", "==", true],
        limitBy: 42,
      };
      let result = await this.$db.getCollection(args);
      this.latest_vacancies = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getLatest();
  },
};
</script>

<style lang="scss" scoped></style>
