<template>
  <div class="login">
    <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
      <b-form
        class="login__form"
        @submit.prevent="signIn"
        v-if="use_case == 'login'"
      >
        <h3 class="pb-4">Вхід</h3>

        <div class="input-group mb-2">
          <label class="d-block w-100">Email</label>
          <b-form-input v-model.trim="user.email"> </b-form-input>
        </div>
        <div class="input-group mb-2">
          <label class="d-block w-100">Пароль</label>
          <b-form-input type="password" v-model.trim="user.password">
          </b-form-input>
        </div>
        <div class="pt-1 pb-3">
          <a href="#" @click.prevent="use_case = 'recovery'">Забули пароль?</a>
        </div>

        <div class="form-group w-100">
          <b-button type="submit" block size="lg" variant="primary w-100"
            >Увійти</b-button
          >
        </div>
        <div class="text-center py-4">
          Немає аккаунту?
          <a href="#" @click.prevent="switchToSignup">Зареєструватись</a>
        </div>
      </b-form>
      <b-form v-if="use_case == 'recovery'" @submit.prevent="resetPassword">
        <h3 class="pb-2">Відновлення доступу</h3>
        <div class="input-group mb-2">
          <label class="d-block w-100">Email</label>
          <b-form-input v-model.trim="user.email"> </b-form-input>
        </div>
        <hr />
        <div class="form-group w-100">
          <b-button type="submit" block size="lg" variant="primary w-100"
            >Скинути пароль</b-button
          >
        </div>
      </b-form>
      <div class="login__password-sent" v-if="use_case == 'email_sent'">
        <h5 class="text-center">
          <div class="mb-4 mt-2 circle-icon">
            <b-icon-envelope-fill />
          </div>
          Листа з подальшими інструкціями надіслано на Вашу електронну адресу
          <br />
          <strong>
            {{ user.email }}
          </strong>
        </h5>
        <div class="pt-2">
          <b-button
            block
            size="lg"
            variant="primary w-100"
            @click="use_case = 'login'"
          >
            Увійти
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      use_case: "login",
      user: {
        email: "",
        password: "",
      },
      error_msg: [
        {
          code: "auth/invalid-email",
          msg: "Невірний Email",
        },
        {
          code: "auth/internal-error",
          msg: "Введіть пароль",
        },
        {
          code: "auth/wrong-password",
          msg: "Невірний пароль",
        },
        {
          code: "auth/user-not-found",
          msg: "Користувача не існує",
        },
        {
          code: "auth/missing-email",
          msg: "Відсутній Email",
        },
      ],
    };
  },
  computed: {},
  methods: {
    switchToSignup() {
      this.$emit("switchToSignUp");
    },
    async resetPassword() {
      this.isLoading = true;
      let result = await this.$auth.senResetEmail(this.user.email);
      console.log(result);
      if (result.success == true) {
        this.use_case = "email_sent";
      } else {
        let correctMsg = this.error_msg.find((el) => {
          return result.errorCode == el.code;
        });
        this.$toast.error(correctMsg.msg);
      }
      this.isLoading = false;
    },
    async signIn() {
      this.isLoading = true;
      var result = await this.$auth
        .signInWithEmail(this.user.email, this.user.password)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          result = error;
        });
      this.isLoading = false;
      if (result.success == true) {
        this.$toast.success("Виконано вхід");
        let claims = result.user.reloadUserInfo.customAttributes;
        if (claims) {
          claims = JSON.parse(claims);
        }

        // if (claims?.admin) {
        //   this.$store.commit("SET_ROLE", "admin");
        //   this.$router.push({ name: "admin-home" });
        // } else {
        //   this.$store.commit("SET_ROLE", "user");
        //   this.$router.push({ name: "candidate-home" });
        // }
      } else {
        let correctMsg = this.error_msg.find((el) => {
          return result.code == el.code;
        });
        this.$toast.error(correctMsg.msg);
      }

      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  .circle-icon {
    height: 64px;
    width: 64px;
    background: rgb(9, 196, 65);
    color: #fff;
    margin: 20px auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
  }
  &__form {
    padding: 3px;
  }
  &__body {
    width: 460px;
    max-width: 90vw;
    margin: 0px auto;
  }
  &__header {
    padding: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      height: 48px;
    }
    .text {
      margin-left: 20px;
      line-height: 1;
      font-weight: 300;
      strong {
        display: block;
        font-weight: strong;
        font-size: 22px;
        opacity: 0.7;
      }
    }
  }
}
</style>
