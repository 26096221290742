<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="my__vacancies">
        <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
          <vacancyCard v-for="v in vacancies" :key="v.uid" :v="v" />
        </b-overlay>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <addVacancyBanner />
    </div>
  </div>
</template>

<script>
import vacancyCard from "@/components/list-items/vacancy-card.vue";
import addVacancyBanner from "@/components/sidebar-banners/add-vacancy-banner.vue";
export default {
  components: {
    vacancyCard,
    addVacancyBanner,
  },
  data() {
    return {
      isLoading: false,
      vacancies: [],
    };
  },
  methods: {
    async getMyVacancies() {
      this.isLoading = true;
      let args = {
        collectionName: "vacancies",
        where_1: ["created_by", "==", this.$store.state.user.uid],
        order: ["updated_at", "desc"],
      };

      let reuslt = await this.$db.getCollection(args);

      this.vacancies = reuslt.docs;

      this.isLoading = false;
    },
  },
  mounted() {
    this.getMyVacancies();
  },
};
</script>

<style lang="scss" scoped>
.my {
  &__vacancies {
  }
}
</style>
