<template>
  <div class="offer">
    <b-card>
      <b-card-text>
        <b-button
          v-if="v.formattedPhone"
          variant="primary"
          @click="contactModal = true"
          >Відгукнутись</b-button
        >
        <b-button target="_blank" v-else :href="v.link" variant="primary">
          Перейти на сайт
        </b-button>
        <b-button
          target="_blank"
          v-if="v.formattedPhone"
          :href="v.link"
          size="sm"
          variant="secondary mt-2"
        >
          Перейти на сайт
        </b-button>
        <div class="offer__sallary-amount" v-if="v.salary">
          {{ v.salary }} <span>грн./міс.</span>
        </div>

        <div class="offer__sallary-amount-detail d-none">договірна</div>
        <div class="line special-line"></div>
        <div class="offer__details">
          <div class="mb-3">Тип зайнятості</div>
          <div class="offer__tags">
            <span v-for="t in v.job_type" :key="t">
              {{ getTypeName(t) }}
            </span>
          </div>
        </div>
        <div class="offer__details">
          Адреса <br />
          <span>
            {{ v.location_string }}
          </span>
        </div>
        <div class="line" v-if="v.contact_name"></div>
        <div class="offer__company d-flex" v-if="v.contact_name">
          <div class="offer__image-company">{{ avatarLetters }}</div>
          <div class="offer__info-about-company">
            <div class="offer_name-company">{{ v.contact_name }}</div>
            <div class="offer_city-company d-none">Вашингтон</div>
          </div>
        </div>
      </b-card-text>
    </b-card>
    <b-modal v-model="contactModal" hide-header hide-footer centered>
      <h6 class="pt-3">Відгукнутись на вакансію</h6>
      <h3>
        {{ v.name }}
      </h3>
      <p class="pt-3 pb-3">
        Контактний номер телефону:
        <strong class="d-block">
          <h4>
            {{ v.formattedPhone }}
          </h4>
        </strong>
      </p>
      <b-button :href="'tel:' + v.formattedPhone" variant="success w-100">
        Зателефонувати
      </b-button>
      <b-button
        @click="contactModal = false"
        variant="secondary mt-3"
        class="w-100"
      >
        Закрити
      </b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["v", "isLoading"],
  data() {
    return {
      contactModal: false,
    };
  },
  computed: {
    job_types() {
      return this.$store.getters.constants.jobtype;
    },
    avatarLetters() {
      let letters = "";
      let words = [];
      if (this.v.contact_name) {
        words = this.v.contact_name.split(" ");
        letters = words[0].substring(0, 2);
        if (words[1]) {
          letters += words[1].substring(0, 1);
        }
      }

      return letters;
    },
  },
  methods: {
    getTypeName(val) {
      return this.job_types.find((el) => {
        return el.value == val;
      }).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  margin-top: 1rem;
  color: var(--primary);
}
.offer {
  &__sallary-amount-detail {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #194dba;
    opacity: 0.75;
    margin: 12px 0 24px 0;
  }
  &__sallary-amount {
    margin-top: 24px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #1d1d1b;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1d1d1b;
      opacity: 0.75;
    }
  }
  &__details {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8e8e8d;
    margin-bottom: 24px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #1d1d1b;
    }
  }
  &__company {
    margin-top: 50px;
    display: flex;
    align-items: center;
  }
  &__tags {
    span {
      background: rgba(25, 77, 186, 0.08);
      border-radius: 50px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      display: inline-block;
      color: #194dba;
      padding: 6px 12px;
      margin-bottom: 8px;
    }
    .tag:last-child {
      margin-bottom: 24px;
    }
  }
  &__image-company {
    background: rgba(29, 29, 27, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 16px;
    text-transform: uppercase;
  }
  &__name-company {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1d1d1b;
  }
  &__city-company {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1d1d1b;
    opacity: 0.7;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #e0e3e9;
  }
  .special-line {
    margin-bottom: 24px;
  }
  button {
    width: 100%;
  }
}
</style>
