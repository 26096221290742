<template>
  <div class="resume-create pt-4">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9">
          <createMain />
        </div>
        <div class="col-12 col-md-3">
          <div class="add-resume-sidebar">
            <h5>Заповнюйте резюме та підвищуйте шанси швидко знайти роботу!</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createMain from "./create-components/create-main.vue";
export default {
  components: {
    createMain,
  },
};
</script>

<style lang="scss" scoped>
.resume-create {
  padding-top: 100px;
}
.add-resume-sidebar {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
