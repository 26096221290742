<template>
  <div class="add-vacancy-banner">
    <h3>Розміщуйте вакансію безкоштовно. Тисячі кандидатів чекають на неї</h3>
    <b-button
      variant="yellow"
      class="mt-3 w-100 d-flex align-items-center justify-content-center"
      :to="{ name: 'vacancy-create' }"
    >
      Розмістити вакансію
    </b-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.add-vacancy-banner {
  background-color: #f2f4f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
