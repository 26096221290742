<template>
  <div class="vacancy-search">
    <div class="vacancy-search__form">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <vacancySearch @search="doSearch" />
          </div>
        </div>
      </div>
    </div>
    <div class="vacancy-search__results">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8">
            <h2 class="mb-3">Результати пошуку серед резюме</h2>
            <b-overlay
              :show="isLoading"
              variant="white"
              style="min-height: 200px"
            >
              <template v-if="!isLoading && resumes.length">
                <resumeCard v-for="r in resumes" :key="r.uid" :r="r" />
              </template>
              <template v-if="!isLoading && !resumes.length">
                <h4>Нічого не знайдено</h4>
                <p>Спробуйте інші критерії пошуку</p>
              </template>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vacancySearch from "@/components/search/vacancy-search.vue";
import resumeCard from "@/components/list-items/resume-card.vue";
export default {
  data() {
    return {
      isLoading: false,
      resumes: [],
    };
  },
  components: {
    resumeCard,
    vacancySearch,
  },
  methods: {
    async doSearch(filter) {
      this.$router.push({
        name: "resume-search",
        query: filter,
      });
      this.isLoading = true;
      let args = {
        collectionName: "resumes",
        where_6: ["is_active", "==", true],
        order: ["updated_at", "desc"],
      };
      if (filter.category) {
        args["where_1"] = ["cat_id", "==", filter.category];
      }
      if (filter.l_2) {
        args["where_2"] = ["location_ids", "array-contains", filter.l_2];
      }
      if (filter.l_3) {
        args["where_2"] = ["location_ids", "array-contains", filter.l_3];
      }

      let result = await this.$db.getCollection(args);
      console.log(result);
      this.resumes = [];
      this.resumes = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.vacancy-search {
  &__form {
    padding: 30px 0px;
  }
  &__results {
    padding: 80px 0px;
    background: #fff;
  }
}
</style>
