<template>
  <div class="resume">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9">
          <b-card>
            <b-overlay
              :show="isLoading"
              variant="white"
              style="min-height: 200px"
            >
              <h4>Резюме</h4>
              <mainInfo :r="resume" :isLoading="isLoading" />
            </b-overlay>
          </b-card>
        </div>
        <div class="col-12 col-md-3">
          <resumeSidebar :r="resume" :isLoading="isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainInfo from "./resume-single-main.vue";
import resumeSidebar from "./resume-single-sidebar.vue";
export default {
  data() {
    return {
      isLoading: false,
      resume: {},
    };
  },
  components: {
    mainInfo,
    resumeSidebar,
  },
  methods: {
    async getResume() {
      this.isLoading = true;

      let uid = this.$route.params.uid;
      if (uid) {
        let result = await this.$db.getDocument("resumes", uid);
        this.resume = result;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getResume();
  },
};
</script>

<style lang="scss" scoped>
.resume {
  background: #f2f4f5;
  padding-top: 74px;
  padding-bottom: 60px;
}
</style>
