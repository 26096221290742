<template>
  <div id="app">
    <iconsCollection />
    <appHeader />
    <main>
      <router-view />
    </main>
    <appFooter />
    <!-- modals -->
    <authModal />
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import authModal from "@/components/modals/auth-modal.vue";

import iconsCollection from "@/components/icons/icons-collection.vue";
export default {
  data() {
    return {
      data() {
        return {
          isLoading: false,
        };
      },
    };
  },
  computed: {
    view() {
      return this.$store.state.view;
    },
  },
  watch: {
    view: {
      handler() {
        this.$router.push({ path: "/" });
      },
    },
  },
  components: {
    appHeader,
    appFooter,
    authModal,
    iconsCollection,
  },
  methods: {
    async getCategories() {
      this.isLoading = true;
      let args = {
        collectionName: "job_categories",
      };
      let result = await this.$db.getCollection(args);
      this.$store.dispatch("setCategories", result.docs);

      this.isLoading = false;
    },
  },
  mounted() {
    this.$auth.onUserAuthChange();
    this.getCategories();
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: #f5f7fa;
  padding-top: 96px;
  & > main {
    margin-bottom: auto;
  }
}
</style>
