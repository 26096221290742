<template>
  <div class="categories">
    <div class="categories__list">
      <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
        <template v-if="!isLoading">
          <router-link
            :to="{ name: 'vacancy-search', query: { category: c.id } }"
            v-for="c in categories"
            :key="c.id"
            class="d-flex justify-content-between"
          >
            {{ c.title }}
            <span class="category__amount ms-4">
              {{ getCount(c.id) }}
            </span>
          </router-link>
        </template>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      counts: {},
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
  },
  methods: {
    async getCounts() {
      this.isLoading = true;

      let result = await this.$db.getDocument("counters", "vacancies");
      this.counts = result;

      this.isLoading = false;
    },
    getCount(id) {
      let count = 0;
      if (this.counts[id]) {
        count = this.counts[id];
      }
      return count;
    },
  },
  mounted() {
    this.getCounts();
  },
};
</script>

<style lang="scss" scoped>
a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
  text-decoration: none;
  margin-bottom: 16px;
  span {
    color: rgba(29, 29, 27, 0.7);
  }
}
</style>
