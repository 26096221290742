import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
} from "firebase/auth";
import db from "@/providers/db";
import store from "../store";

function authService(app) {
  const auth = getAuth(app);
  auth.languageCode = "uk";
  return {
    senResetEmail: async (email) => {
      let result = await sendPasswordResetEmail(auth, email)
        .then(() => {
          return {
            success: true,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          return {
            success: false,
            errorCode: errorCode,
          };
        });
      return result;
    },
    logOut: async () => {
      var result = await signOut(auth)
        .then(() => {
          result = true;
          store.commit("setUser", null);
          store.commit("SET_ROLE", "anonymous");
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
          result = false;
        });
      return result;
    },
    createUserWithEmail: async (email, password) => {
      var result = await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          store.commit("setUser", user);
          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });
      return result;
    },
    updatePassword: async (newPassword) => {
      const user = auth.currentUser;

      let result = await updatePassword(user, newPassword)
        .then(() => {
          return { result: true };
        })
        .catch((error) => {
          return error;
        });

      return result;
    },
    signInWithEmail: async (email, password) => {
      var result = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          return {
            success: true,
            user: user,
          };
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          return {
            error: true,
            code: errorCode,
            message: errorMessage,
          };
        });
      console.log(result);
      return result;
    },
    onUserAuthChange: async () => {
      var result = await onAuthStateChanged(auth, async (user) => {
        if (user) {
          store.commit("setUser", user);
          const uid = user.uid;
          let userData = await db(app).getDocument("users", uid);

          store.commit("setUserData", userData);

          var claims = user.reloadUserInfo.customAttributes;
          if (claims) {
            claims = JSON.parse(claims);
          }
          store.commit("SET_ROLE", "user");
          if (claims?.user) {
            store.commit("SET_ROLE", "user");
          }
          if (claims?.admin) {
            store.commit("SET_ROLE", "admin");
          }
          return {
            is_signed: true,
            uid: uid,
          };
        } else {
          store.commit("SET_ROLE", "anonymous");
          return {
            is_signed: false,
          };
        }
      });
      return result;
    },
  };
}

export { authService as default };
