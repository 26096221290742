import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import length from "@/components/characters-length.vue";
Vue.component("text-length", length);

//Icon component
import icon from "@/components/icons/icon-component.vue";
Vue.component("icon", icon);

//Algolia search
import InstantSearch from "vue-instantsearch";
Vue.use(InstantSearch);

//Vue-select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

//Tel input
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
let vueTelOptions = {
  inputOptions: {
    placeholder: "Номер телефону",
  },
};
Vue.use(VueTelInput, vueTelOptions);

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  timeout: 1500,
  draggable: false,
  closeOnClick: true,
};

//Vue moment
Vue.use(Toast, options);
const moment = require("moment");
require("moment/locale/uk");
Vue.use(require("vue-moment"), {
  moment,
});

//App global scss
import "@/scss/style.scss";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBw1DnIh5oMQxmPoKoRA4Q2OZYxaVAkKaA",
  authDomain: "erobota-ua.firebaseapp.com",
  projectId: "erobota-ua",
  storageBucket: "erobota-ua.appspot.com",
  messagingSenderId: "973187220831",
  appId: "1:973187220831:web:18bab0c45f397e24b462ea",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import acl from "./acl/acl";

//Firebase Auth services
import auth from "./providers/auth";
Vue.prototype.$auth = auth(app);

//Firebase Auth services
import db from "./providers/db";
Vue.prototype.$db = db(app);

//Firebase Auth services
import storage from "./providers/storage";
Vue.prototype.$storage = storage(app);

//AXIOS as API
import api from "./providers/api";
Vue.prototype.$api = api;

Vue.config.productionTip = false;

new Vue({
  acl,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
