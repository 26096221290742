<template>
  <div class="admin__latest-users pt-4">
    <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
      <div v-for="u in users" :key="u.uid">
        <router-link
          class="user-item"
          :to="{ name: 'admin-user', params: { uid: u.uid } }"
        >
          <div class="user-item__name">
            <b-icon-person-fill />
            {{ u.first_name }} {{ u.last_name }}
          </div>

          <div class="user-item__email">
            {{ u.email }}
          </div>
          <div class="ms-auto user-item__labels">
            <div class="label" v-if="u.isAdmin">admin</div>
          </div>
        </router-link>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      users: [],
    };
  },
  components: {},
  methods: {
    async getLatest() {
      this.isLoading = true;
      let args = {
        collectionName: "users",
      };
      let result = await this.$db.getCollection(args);
      this.users = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getLatest();
  },
};
</script>

<style lang="scss" scoped>
.user-item {
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  &__labels {
    div {
      background: #f00;
      color: #fff !important;
      border-radius: 4px;
      padding: 3px 8px;
      font-size: 13px;
      line-height: 1;
    }
  }
  &__name {
    width: 360px;
  }
  &__email {
    color: #999;
  }
  & > div {
    margin-right: 20px;
  }
}
</style>
