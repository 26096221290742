<template>
  <div class="my">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <h3>
            Привіт, <strong>{{ user.email }}</strong>
          </h3>
        </div>
      </div>
    </div>
    <div class="my__bg py-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ui-tabs pt-3">
              <button
                v-for="tab in availavleTabs"
                class="ui-tabs__button"
                :class="{ active: tab.id == current_tab }"
                :key="tab.id"
                @click="setTab(tab.id)"
              >
                {{ tab.name }}
              </button>
            </div>
          </div>
          <div class="col-12">
            <template v-if="current_tab == 'vacancies'">
              <h2 class="my-3">Мої вакансії</h2>
              <vacanciesTab />
            </template>
            <template v-if="current_tab == 'profile'">
              <h2 class="my-3">Мій профіль</h2>
              <profileTab />
            </template>
            <template v-if="current_tab == 'resumes'">
              <h2 class="my-3">Мої резюме</h2>
              <resumesTab />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vacanciesTab from "./tabs/my-vacancies.vue";
import profileTab from "./tabs/my-profile.vue";
import resumesTab from "./tabs/my-resumes.vue";
export default {
  components: {
    vacanciesTab,
    profileTab,
    resumesTab,
  },
  data() {
    return {
      current_tab: "vacancies",
      tabs: [
        {
          name: "Вакансії",
          id: "vacancies",
          view: ["workers"],
        },
        {
          name: "Резюме",
          id: "resumes",
          view: ["vacancies"],
        },
        {
          name: "Профіль",
          id: "profile",
          view: ["vacancies", "workers"],
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.setTab(this.$route.query.tab);
      },
    },
  },
  computed: {
    availavleTabs() {
      return this.tabs.filter((tab) => {
        return tab.view.includes(this.view);
      });
    },
    user() {
      return this.$store.state.user;
    },
    view() {
      return this.$store.state.view;
    },
  },
  methods: {
    setTab(id) {
      this.current_tab = id;
      this.$router.replace({ name: "my", query: { tab: id } });
    },
  },
  mounted() {
    if (this.$route.query.tab) {
      this.setTab(this.$route.query.tab);
    }
  },
};
</script>

<style lang="scss" scoped>
.my {
  h3 {
    font-weight: 300;
    strong {
      font-weight: bold;
    }
  }
  &__bg {
    background-color: #fff;
  }
}
</style>
