<template>
  <div class="home-search__vacancy">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Пошук роботи в Україні</h1>
          <vacancySearch :params="filter" @search="doSearch" />
          <div class="custom-checkbox mt-3">
            <label for="job-types">
              <input
                id="job-types"
                type="checkbox"
                v-model="filter.job_types"
                :value="400"
                name="jobtype"
              />
              <div class="box">
                <b-icon-check />
              </div>
              <span>
                Одноразовий підробіток
                <i class="new-badge">Нове</i>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vacancySearch from "@/components/search/vacancy-search.vue";
export default {
  data() {
    return {
      filter: {},
    };
  },
  components: {
    vacancySearch,
  },
  methods: {
    doSearch(query) {
      this.$router.push({
        name: "vacancy-search",
        query: query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-search {
  &__vacancy {
    padding: 60px 0px;
  }
}
h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 1.2;
  @media (max-width: 768px) {
    font-size: 48px !important;
    line-height: 1 !important;
  }
}
</style>
