<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="ui-tabs pt-3">
          <button
            v-for="tab in tabs"
            class="ui-tabs__button"
            :class="{ active: tab.id == current_tab }"
            :key="tab.id"
            @click="setTab(tab.id)"
          >
            {{ tab.name }}
          </button>
        </div>
      </div>
      <div class="col-12">
        <template v-if="current_tab == 'users'">
          <h2 class="my-3">Користувачі</h2>
          <usersTab />
        </template>
        <template v-if="current_tab == 'vacancies'">
          <h2 class="my-3">Вакансії</h2>
          <vacanciesTab />
        </template>
        <template v-if="current_tab == 'resumes'">
          <h2 class="my-3">Резюме</h2>
          <resumesTab />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import usersTab from "./tabs/users-tab.vue";
import vacanciesTab from "./tabs/vacancies-tab.vue";
import resumesTab from "./tabs/resumes-tab.vue";
export default {
  components: {
    usersTab,
    vacanciesTab,
    resumesTab,
  },
  data() {
    return {
      current_tab: "users",
      tabs: [
        {
          name: "Користувачі",
          id: "users",
        },
        {
          name: "Вакансії",
          id: "vacancies",
        },
        {
          name: "Резюме",
          id: "resumes",
        },
      ],
    };
  },
  methods: {
    setTab(id) {
      this.current_tab = id;
      this.$router.replace({ name: "admin-home", query: { tab: id } });
    },
  },
  mounted() {
    if (this.$route.query.tab) {
      this.setTab(this.$route.query.tab);
    }
  },
};
</script>

<style lang="scss" scoped>
.admin {
  &__bg {
    background: #fff;
    padding: 20px 0px 40px;
  }
}
</style>
