<template>
  <div>
    <homeVacancies v-if="view == 'vacancies'" />
    <homeWorkers v-if="view == 'workers'" />
  </div>
</template>

<script>
import homeVacancies from "./vacancies/";
import homeWorkers from "./workers/index.vue";
export default {
  data() {
    return {};
  },
  components: {
    homeVacancies,
    homeWorkers,
  },
  computed: {
    view() {
      return this.$store.state.view;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
