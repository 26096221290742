<template>
  <div class="learning-resources">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="me-4">
            <h2>Навчальні матеріали</h2>
            <p>
              Ми зібрали в одному місці ресурси, <br />де можна отримати
              безкоштовне навчання.
            </p>
            <img src="@/assets/img/learn.svg" class="w-50 mt-4" alt="" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <b-overlay :show="isLoading" variant="transparent">
            <div v-for="m in materials" :key="m.uid">
              <div class="item">
                <h4>{{ m.organisation_name }}</h4>
                <div class="langs mb-2">
                  <span v-for="l in processLangs(m.langs)" :key="l">
                    <strong>
                      {{ l }}
                    </strong>
                  </span>
                </div>
                <p>
                  {{ m.education_method }}
                </p>
                <RichTextRenderer v-if="m.contacts" :document="m.contacts" />
                <a v-if="m.websiteUrl" target="_blank" :href="m.websiteUrl">
                  <b-icon-globe />
                  Веб сайт</a
                >
              </div>
            </div>
          </b-overlay>
          <b-button variant="primary w-100 mt-4" :to="{ name: 'learn' }">
            Більше матеріалів
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  components: {
    RichTextRenderer,
  },
  data() {
    return {
      isLoading: false,
      materials: [],
    };
  },
  methods: {
    processLangs(langs_raw) {
      let langs = [];
      if (typeof langs_raw == "string") {
        langs = langs_raw.split(",");
      } else {
        langs = langs_raw;
      }
      return langs;
    },
    async getMaterials() {
      this.isLoading = true;
      let args = {
        collectionName: "educational_resources",
        limitBy: 3,
      };
      let result = await this.$db.getCollection(args);
      if (result.docs.length) {
        this.materials = result.docs;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getMaterials();
  },
};
</script>

<style lang="scss" scoped>
.learning-resources {
  padding: 60px 0px;
  background: #e1f2fe;
  h2 {
    font-size: 48px;
  }
  .item {
    border-bottom: 1px solid rgba(#000, 0.3);
    margin-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .langs {
      font-size: 13px;
    }
  }
}
</style>
