<template>
  <div class="home__latest-vacancies">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <h2 class="mb-3">Нові вакансії</h2>
          <b-overlay
            :show="isLoading"
            variant="white"
            style="min-height: 200px"
          >
            <vacancyCard v-for="v in latest_vacancies" :key="v.uid" :v="v" />
          </b-overlay>
        </div>
        <div class="col-12 col-md-4">
          <h4 class="mb-4">Вакансії за категоріями</h4>
          <categoriesHome />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vacancyCard from "@/components/list-items/vacancy-card.vue";
import categoriesHome from "@/views/home/vacancies/home-categories-sidebar.vue";
export default {
  data() {
    return {
      isLoading: false,
      latest_vacancies: [],
    };
  },
  components: {
    vacancyCard,
    categoriesHome,
  },
  methods: {
    async getLatest() {
      this.isLoading = true;
      let args = {
        collectionName: "vacancies",
        order: ["updated_at", "desc"],
        where_1: ["is_active", "==", true],
        limitBy: 12,
      };
      let result = await this.$db.getCollection(args);
      this.latest_vacancies = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getLatest();
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__latest-vacancies {
    padding: 80px 0px;
    background: #fff;
  }
}
</style>
