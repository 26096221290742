<template>
  <div class="search">
    <div class="search__filter">
      <div class="row">
        <div class="col-12 col-lg-10">
          <div class="search__inputs flex-column flex-lg-row">
            <div class="category w-100 d-flex align-items-center">
              <b-icon-search class="search" />
              <div class="w-100">
                <v-select
                  v-model="filter.category"
                  :options="categories"
                  :reduce="(category) => category.value"
                  :clearable="false"
                  placeholder="Оберіть категорію"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <icon :variant="'dropdown'" />
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="location">
              <location-select @select="setFilterLocation" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <b-button
            @click="doSearch"
            variant="primary"
            class="w-100 search__cta"
            size="lg"
          >
            <strong> Знайти </strong></b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locationSelect from "./location-select.vue";

export default {
  components: { locationSelect },
  data() {
    return {
      filter: {
        location: {},
        category: null,
      },
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories.map((el) => {
        return {
          value: el.id,
          label: el.title,
        };
      });
    },
  },
  methods: {
    setFilterLocation(location) {
      this.filter.location = location;
    },
    doSearch() {
      this.isLoading = true;
      let query = {
        category: this.filter.category,
        ...this.filter.location.ids,
      };
      this.$emit("search", query);

      this.isLoading = false;
    },
    checkQuery() {
      if (this.$route.query.category) {
        this.filter.category = Number(this.$route.query.category);
      }
      if (this.$route.query.l_1) {
        this.filter.location.l_1 = this.$route.query.l_1;
      }
      if (this.$route.query.l_2) {
        this.filter.location.l_2 = this.$route.query.l_2;
      }
      if (this.$route.query.l_3) {
        this.filter.location.l_3 = this.$route.query.l_3;
      }
    },
  },
  mounted() {
    this.checkQuery();
    if (this.$route.path !== "/") {
      this.doSearch();
    }
  },
};
</script>

<style lang="scss">
.search {
  .btn {
    height: 72px;
    font-size: 18px !important;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
  &__inputs {
    height: 72px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 20px;
    padding-right: 40px;
    border-radius: 8px;
    @media (max-width: 991px) {
      height: auto;
      padding-right: 0px;
    }

    .category {
      @media (min-width: 992px) {
        border-right: 1px solid #e4e4e4;
      }
      @media (max-width: 991px) {
        position: relative;
        &::after {
          display: block;
          height: 1px;
          background: #f5f7fa;
          content: "";
          width: calc(100% + 20px);
          left: -20px;
          position: absolute;
          top: 100%;
        }
      }
    }
    .category,
    .location {
      @media (max-width: 991px) {
        min-width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
      }
    }
    .vs__dropdown-toggle {
      border: none !important;
      input {
        box-shadow: none;
        font-size: 18px !important;
        font-weight: 600;
        &::placeholder {
          color: #ccc;
        }
      }
    }

    svg.search {
      margin-right: 20px;
    }
  }
}
</style>
