<template>
  <div class="admin__latest-users pt-4">
    <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
      <resumeCard v-for="r in resumes" :key="r.uid" :r="r" />
    </b-overlay>
  </div>
</template>

<script>
import resumeCard from "@/components/list-items/resume-card.vue";
export default {
  components: {
    resumeCard,
  },
  data() {
    return {
      isLoading: false,
      resumes: [],
    };
  },
  methods: {
    async getLatest() {
      this.isLoading = true;
      let args = {
        collectionName: "resumes",
      };
      let result = await this.$db.getCollection(args);
      this.resumes = result.docs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getLatest();
  },
};
</script>

<style lang="scss" scoped>
.user-item {
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  &__labels {
    div {
      background: #f00;
      color: #fff !important;
      border-radius: 4px;
      padding: 3px 8px;
      font-size: 13px;
      line-height: 1;
    }
  }
  &__name {
    width: 360px;
  }
  &__email {
    color: #999;
  }
  & > div {
    margin-right: 20px;
  }
}
</style>
