<template>
  <b-card class="candidate-single" :class="{ deactivated: !r.is_active }">
    <b-overlay :show="updateLoading" variant="white">
      <div class="candidate__author d-flex">
        <div
          class="candidate__image-author d-flex align-items-center justify-content-center"
        >
          {{ r.contact_name[0] }}
        </div>
        <div class="candidate__post">
          <div class="candidate__name-author">
            <strong>
              {{ r.contact_name }}
            </strong>
          </div>
          <div class="candidate__about-post d-flex">
            <div class="candidate__post-city">
              <router-link
                :to="{
                  name: 'resume-search',
                  query: {
                    l_1: r.location.ids.l_1,
                    l_2: r.location.ids.l_2,
                    l_3: r.location.ids.l_3,
                  },
                }"
              >
                {{ r.location_string }}
              </router-link>
            </div>
            <span class="ms-1 me-1">•</span>
            <div class="candidate__post-time">
              {{ r.updated_at.seconds | moment("calendar") }}
            </div>
          </div>
        </div>
      </div>
      <div class="candidate__title-of-work">
        <router-link :to="{ name: 'resume-single', params: { uid: r.uid } }">
          <h4>
            {{ r.name }}
          </h4>
        </router-link>
      </div>
      <div class="candidate__tags">
        <div class="tag" v-for="t in r.job_type" :key="t">
          {{ getTypeName(t) }}
        </div>
      </div>

      <div
        class="work__controls d-column d-md-flex pt-3"
        v-if="(user && r.created_by == user.uid) || role == 'admin'"
      >
        <b-button
          variant="primary me-2"
          size="sm"
          :to="{ name: 'resume-edit', params: { uid: r.uid } }"
        >
          Редагувати
        </b-button>
        <b-button variant="danger me-2" @click="removeModal = true" size="sm">
          Видалити
        </b-button>

        <b-button
          class="ms-auto"
          @click="toggleActive"
          size="sm"
          :variant="r.is_active ? 'secondary' : 'black'"
        >
          <span v-if="r.is_active"> Зняти з публікації </span>
          <span v-else> Опублікувати </span>
        </b-button>
      </div>
    </b-overlay>
    <b-modal v-model="removeModal" centered hide-footer hide-header>
      <b-overlay :show="isLoading" variant="white" style="min-height: 200px">
        <h2 class="mb-2">Справді видалити резюме?</h2>
        <h2 class="mb-3">
          <strong>{{ r.name }}</strong>
        </h2>
        <b-button @click="removeResume" class="w-100 mt-2" variant="danger"
          >Видалити вакансію</b-button
        >
        <b-button
          @click="removeModal = false"
          class="w-100 mt-2"
          variant="outline-danger"
          >Скасувати</b-button
        >
      </b-overlay>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  props: ["r"],
  data() {
    return {
      isLoading: false,
      updateLoading: false,
      removeModal: false,
    };
  },
  computed: {
    job_types() {
      return this.$store.getters.constants.jobtype;
    },
    role() {
      return this.$store.getters.role;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async removeResume() {
      this.isLoading = true;
      let result = await this.$db.delete("resumes", this.v.uid);
      if (result.success) {
        this.removeModal = false;
        this.$toast.warning("Резюме видалено");
        this.$emit("remove");
      }
      this.isLoading = false;
    },
    async toggleActive() {
      this.updateLoading = true;
      let active = !this.r.is_active;
      let now = new Date();
      let result = await this.$db.update("resumes", this.r.uid, {
        updated_at: now,
        is_active: active,
      });

      if (result.success) {
        this.$toast.success("Резюме оновлено");
        this.r.is_active = active;
        this.r.updated_at = now;
      }

      this.updateLoading = false;
    },
    getTypeName(val) {
      return this.job_types.find((el) => {
        return el.value == val;
      }).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.candidate-single {
  border: 1px solid #e7e7e7;
  &.deactivated {
    filter: grayscale(1);
  }
}
.candidate {
  &__image-author {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    font-weight: bold;
    margin-right: 16px;
  }
  &__about-post {
    font-size: 14px;
    margin-bottom: 12px;
    a {
      text-decoration: none;
    }
  }
  &__title-of-work {
    a {
      color: #0d6efd;
      text-decoration: none;
    }
  }
  &__tags {
    .tag {
      font-style: normal;
      margin-right: 8px;
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #194dba;
      padding: 4px 8px;
      background: #edf1fa;
      border-radius: 40px;
    }
  }
}
.card {
  margin-bottom: 20px;
}
</style>
