import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";
import store from "@/store";

Vue.use(AclInstaller);

let initialRole = "anonymous";

// const userInfo = JSON.parse(localStorage.getItem("userInfo"));

console.log(initialRole);
export default new AclCreate({
  initial: initialRole,
  notfound: "login",
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule("admin").generate(),
    user: new AclRule("user").or("admin").generate(),
    anonymous: new AclRule("anonymous").or("user").or("admin").generate(),
  },
  middleware: async (acl) => {
    if (store.state.user && store.state.role) {
      acl.change(store.state.role);
    }
  },
});
