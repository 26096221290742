<template>
  <div class="header__user">
    <template v-if="!user">
      <a href="#" @click.prevent="openAuthModal">Увійти</a>
      <a href="#" @click.prevent="openSignupModal">Зареєструватись</a>
    </template>
    <template v-if="user">
      <div class="d-flex align-items-center">
        <b-button
          v-if="view == 'vacancies'"
          :to="{ name: 'resume-create' }"
          class="btn-white"
        >
          Розмістити резюме
        </b-button>
        <b-button
          v-if="view == 'workers'"
          :to="{ name: 'vacancy-create' }"
          class="btn-white"
        >
          Розмістити вакансію
        </b-button>
        <a
          href="#"
          class="mb-0 d-flex align-items-center sidebar-toggle"
          @click.prevent="handleOpen"
        >
          <b-icon-person-circle />
          <span class="d-none d-md-block">
            {{ user.email }}
          </span>
        </a>
      </div>
    </template>
    <b-sidebar
      v-if="user && user.uid"
      v-model="userSidebar"
      id="sidebar-backdrop"
      backdrop-variant="dark"
      backdrop
      no-header
      no-footer
      shadow
      right
    >
      <div class="sidebar">
        <div class="sidebar__header py-4 px-4">
          <h3 class="mb-0 mt-4">Привіт,</h3>
          <h5>
            <strong> {{ user.email }}</strong>
          </h5>
        </div>
        <div class="sidebar__menu">
          <ul>
            <li>
              <router-link :to="{ name: 'my', query: { tab: 'profile' } }">
                <b-icon-person-fill />
                <span> Профіль</span>
              </router-link>
            </li>
            <li v-if="view == 'workers'">
              <router-link :to="{ name: 'my', query: { tab: 'vacancies' } }">
                <b-icon-list />
                <span> Мої вакансії</span>
              </router-link>
            </li>

            <li v-if="view == 'vacancies'">
              <router-link :to="{ name: 'my', query: { tab: 'resumes' } }">
                <b-icon-list />
                <span> Мої резюме</span>
              </router-link>
            </li>

            <li v-if="role == 'admin'">
              <router-link :to="{ name: 'admin-home' }">
                <b-icon-gear-fill />
                <span> Адміністрування</span>
              </router-link>
            </li>

            <li>
              <a @click.prevent="signOut" href="#" class="logout">
                <b-icon-lock-fill />
                <span> Вийти</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userSidebar: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    user() {
      return this.$store.state.user;
    },

    view() {
      return this.$store.state.view;
    },
  },
  methods: {
    handleOpen() {
      this.userSidebar = !this.userSidebar;
    },
    signOut() {
      this.$auth.logOut();
    },
    openAuthModal() {
      const event = new Event("openAuthLogin");
      window.dispatchEvent(event);
    },
    openSignupModal() {
      const event = new Event("openAuthSignup");
      window.dispatchEvent(event);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #1d1d1b;
  text-decoration: none;
  margin-left: 16px;
  transition: 0.3s;
  border: none;
  font-weight: bold !important;
  cursor: pointer;
  &.btn {
    padding: 0px 22px;
  }
  &:hover {
    transition: 0.3s;
    color: #1d1d1b;
    opacity: 0.8;
  }
}
.btn-white {
  &:hover {
    opacity: 1;
    background: var(--primary-color);
    color: #fff !important;
  }
}
.sidebar-toggle {
  svg {
    width: 24px;
    height: 24px;
    @media (min-width: 768px) {
      margin-right: 12px;
    }
  }
}
.sidebar {
  &__menu {
    ul {
      list-style: none;
      padding: 0;
      margin: 20px 0px 30px;
      li {
        padding: 4px;
        a {
          display: block;
          position: relative;
          padding: 12px 12px;
          border-radius: 6px;
          color: #666;
          transition: 0.3s;
          &.logout {
            color: rgb(151, 23, 23);
            &:hover {
              background: rgba(151, 23, 23, 0.1);
              color: rgb(151, 23, 23);
            }
          }
          &:hover {
            background: #e7e7e7;
            color: #000;
            transition: 0.3s;
          }
          svg {
            margin-right: 12px;

            width: 22px;
          }
          font-size: 18px;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
  &__header {
    h3 {
      opacity: 0.7;
      font-weight: 300;
    }
    background: #000;
    color: #fff;
  }
}
</style>
